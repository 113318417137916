import React from 'react';

const Validacion = () => {
  return (
    <div>
      Usuario no valido
    </div>
  );
}

export default Validacion;
